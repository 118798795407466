import { ShopList, ShopPagination } from '@/store/modules/shop/state'

export interface GetShopQueryParams {
    search: string|null;
    referralCode: string|null;
    status?: KycStatus|null;
    offset: number;
    limit: number;
    startDate?: string;
    endDate?: string;
    adminSupportStatus?: ADMIN_SUPPORT_STATUS;
}

export enum KycStatus {
    APPROVE = 'APPROVE',
    REJECT = 'REJECT',
    IN_REVIEW = 'IN_REVIEW',
    WAITING = 'WAITING'
}

export enum StopSentStatus {
    STOP_SENT = 'STOP_SENT'
}

export enum ADMIN_SUPPORT_STATUS {
    PENDING = 'PENDING',
    QUALIFY = 'QUALIFY',
    COMPLETED = 'COMPLETED',
    STOP_SENT = 'STOP_SENT'
  }

export interface ShopResponse {
    data: ShopList[];
    pagination: ShopPagination;
}

export interface ShopKyc {
    _id: string;
    kyc: Kyc;
}

export interface Kyc {
    info:        Info;
    remarkIssue: any[];
    _id:         string;
    type:        string;
    filePath:    KycFilePath;
    address:     Address;
    status:      string;
    updatedAt:   Date;
    createdAt:   Date;
    files:       File[];
    allKycFile:  any;
}

export interface KycFilePath {
    companyEvidence?: string;
    bookBank?: string;
    idCard?: string;
}

export interface Address {
    _id:             string;
    desc1:           string;
    provinceCode:    number;
    districtCode:    number;
    subDistrictCode: number;
    zipCode:         number;
}

export interface File {
    name: string;
    url:  string;
}

export interface Info {
    taxId:       string;
    companyName: string;
    corpType:    string;
    citizenId:   string;
    firstname:   string;
    lastname:    string;
    phoneNo:     string;
}
export interface IPayloadAddBooking {
    status: string;
    bank?: {
        accountName: string;
        accountNumber: string;
        accountIssuerId: string;
    }
}

export type AdjustRatePayload = {
    rateName: string;
    shopId: string;
}

export type updateStatusPayload = {
    status: string;
}

interface WeightRate {
    _id: string;
    minWeight: number;
    maxWeight: number;
    minSize: number;
    maxSize: number;
    rate_BKKC_UPC: number;
    rate_BKKC_BKKC: number;
    rate_BKKC_P: number;
    rate_UPC_UPC: number;
    rate_UPC_BKKC: number;
    rate_UPC_P: number;
  }

interface CodPeriod {
    transferDate: number
    startDiff: number
    endDiff: number
  }
  
export interface IShopRates {
pricePolicy: string;
_id: string;
courier: string;
codPercent: number;
weightRates: WeightRate[];
sizeRange: number
weightRange: number
incrementRatePerRange: number
freeRemoteZone: boolean
codPeriod: CodPeriod[];
codPeriodType: string;
}
  
export enum TYPE_COMPANY{
    PARTNERSHIP = 'ห้างหุ้นส่วนสามัญ',
    SHOP = 'ร้านค้า',
    CORPORATE_GROUP = 'คณะบุคคล',
    LIMITED_COMPANY = 'บริษัทจำกัด',
    PUBLIC_COMPANY = 'บริษัทมหาชนจำกัด',
    LIMITED_PARTNERSHIP = 'ห้างหุ้นส่วนจำกัด',
    FOUNDATION = 'มูลนิธิ',
    ASSOCIATION = 'สมาคม',
    JOINT_VENTURE = 'กิจการร่วมค้า',
    OTHER = 'อื่น ๆ',
  }